/*****  CUSTOM FUNCTIONS *****/

/* Fonction pour afficher ou cacher le shad
Paramamètres : 
    shad => correspond à l'ID du shad à séléctionné
    action =>   - add pour afficher le shad
                - delete pour cacher le shad
*/
function triggerShad(shad, action) {
    shad = shad ? ('#shad_' + shad) : '#shad';
    if (action && action == 'add') {
        $(shad).addClass('actif');
    } else if (action) {
        $(shad).removeClass('actif');
    } else {
        return;
    }
};

/* Fonction pour gérer le sticky par rapport à la position du haut de la page */
function onWinScroll() {
    var siteHeader = $("#site_head_wrap");
    var scrollTop = $(document).scrollTop();
    /* Si le header n'a pas la class sticky et qu'on n'est pas en haut de page => Ajout de la class Sticky */
    if (!siteHeader.hasClass("sticky") && scrollTop !== 0) {
        siteHeader.addClass("sticky");
        /* Si on est en haut de page, on enlève la class Sticky */
    } else if (siteHeader.hasClass("sticky") && scrollTop === 0) {
        siteHeader.removeClass("sticky");
    }
}

/* Fonction pour cacher le preheader */
function hidePreHeader() {
    $(".preheaderWrapper").hide();
    $("#site_head_wrap").removeClass('bandeau_on');
}

/* Fonction pour fermer la lightbox avantage */
function closePopin(namePopin, nameShad) {
    if ($(namePopin).hasClass("actif")) {
        $(namePopin).removeClass("actif")
        triggerShad(nameShad, 'remove');
    }
}

/* Fonction pour ouvrir ou fermer un sous menu (MENU BURGER)
Paramamètres : 
    subMenu => class de l'entrée du menu à ouvrir
*/
function displaySubMenu(subMenu) {
    /* Au clique sur une entrée du menu */
    $(subMenu).on('click touchstart', function (evt) {
        /* En mode Menu Burger */
        if ($('.header_main_wrapper').hasClass("burgerMenu")) {
            evt.preventDefault();       // Action par défault non exécutée

            /* Cacher le sous menu s'il est ouvert */
            if ($(this).hasClass("actif")) {
                $(this).removeClass("actif");               // Supprimer class "actif" sur l'entrée cliquée
                $(this).next().fadeOut();                  // Cacher le Sous-Menu

                /* Afficher le sous menu s'il est fermé */
            } else {
                $(this).addClass("actif");                  // Ajouter class "actif" sur l'entrée cliquée
                $(this).next().fadeIn();                   // Afficher le Sous-Menu
            }
        }
    });
}

/* Fonction pour afficher ou cacher le shad du menu lors du survol de la catégorie (MENU CLASSIQUE)
Paramamètres : 
    event =>    évènement "mouseenter" pour afficher le shad
                évènement "mouseleave" pour cacher le shad
*/
function displayShadMenu(event) {
    /* Définir l'état de l'affichage du shad */
    var paramShad;
    if (event == "mouseenter") {
        paramShad = 'add';
    } else if (event == "mouseleave") {
        paramShad = 'remove';
    }

    /* Evènement appliqué sur la catégorie */
    $('.categ_item').on(event, function (evt) {
        /* En mode Menu Classique */
        if (!$('.header_main_wrapper').hasClass("burgerMenu")) {
            triggerShad('menu', paramShad);
        }
    });
}

/* Fonction pour ouvrir le rollover de l'item cliqué (Fonction pour version TABLETTE)
Paramamètres : 
    idRollover => id de l'item à ourvrir
*/
function displayRolloverItem(idRollover) {
    /* Au clique sur l'entrée du rollover */
    $(idRollover).on("touchstart", function (evt) {
        /* Si rollover pas ouvert => Affichage du rollover avec le shad rolloverMenu */
        if (!$(this).hasClass("actif")) {
            evt.preventDefault();                                                   // Action par défault non exécutée
            closePopin(".categ_item", "menu");                                      // Fermer les sous menus ouverts et cacher le shad menu
            closePopin("#advantage_lightbox", "rollovermenu");                      // Fermer la lightbox avantage et cacher le shad
            $(".header_item:not(#" + $(this)[0].id + ")").removeClass("actif");     // Fermer tous les autres rollovers sauf celui qu'on a sélectionné
            $(this).addClass("actif");                                              // Ouvrir le rollover de l'id séléctionné
            triggerShad('rollovermenu', 'add');                                     // Afficher le shad rollovermenu
        }
        /* Sinon Redirection */
    });
}

/* Fonction pour faire apparaitre les options d'un filtre sélectionné
    Paramamètres : 
        elem => élément sélectionné
        pushCat => nom de la class de l'élément suivant
*/
function triggerDropdown(elem, toDrop) {
    if ($(elem).hasClass('actif')) {            // Si l'élement cliqué est actif, on enlève des class "actif" et on cache le bloc suivant spécifique
        $(elem).removeClass('actif');
        $(elem).next(toDrop).removeClass('actif');
    } else {                                    // Si l'élement cliqué est non actif, on ajoute des class "actif" et on affiche le bloc suivant spécifique
        $('.filters_products .filter_name.actif').removeClass('actif');
        $('.filters_products .selected_options.actif').removeClass('actif');
        $(elem).addClass('actif');
        $(elem).next(toDrop).addClass('actif');
    }
}

$(function () {
    /* -------------------- HEADER STICKY SCROLL -------------------- */
    onWinScroll();
    $(window).on("scroll", onWinScroll);

    /* ______________________________________ FONCTION MENU ______________________________________ */

    /* -------------------- MENU BURGER -------------------- */
    /* Clique sur le logo menu burger, ouverture du menu burger et activation du shad burgermenu */
    $('.burger_menu_container').on('click touchstart', function (evt) {
        $('.header_main_wrapper').addClass('actif');
        triggerShad('burgemenu', 'add');
    });

    /* Clique sur l'icône fermeture du menu burger ou sur le shad du burgemenu, fermeture du menu burger et désactivation du shad burgemenu */
    $('.burger_menu_close, #shad_burgemenu').on('click touchstart', function (evt) {
        $('.header_main_wrapper').removeClass('actif');
        triggerShad('burgemenu', 'remove');
    });

    /*** Si Largeur Device infèrieure à 1440px (Navigateur sans Scrollbar 1423px), on est en mode MENU BURGER ***/
    if ($(window).width() <= 1423) {
        $(".header_main_wrapper").addClass("burgerMenu");   // Ajouter la class burgerMenu qui informe que le menu est en Menu Burger
        var osInstance = OverlayScrollbars(document.getElementById('scrollbar_burgerMenu'), {});  // Initialisation de la scrollbar des entrées du Menu Burger
    }

    /*** Fonction pour Afficher ou Cacher les Sous-catégories ou les Objets du Menu Burger ***/
    displaySubMenu(".hasSubCategories");
    displaySubMenu(".hasObjets");
    displaySubMenu(".gondole_title");


    /* -------------------- MENU CLASSIQUE -------------------- */
    /*** Fonction pour Afficher ou Cacher le shad lors ouverture d'un sous menu  ***/
    displayShadMenu('mouseenter');
    displayShadMenu('mouseleave');

    /* ______________________________________ RESIZE NAVIGATEUR ______________________________________ */
    $(window).on("resize", function () {
        /* ---------- Passage MENU BURGER => MENU CLASSIQUE ---------- */
        if ($(window).width() > 1423 && $(".header_main_wrapper").hasClass("burgerMenu")) {

            $(".header_main_wrapper").removeClass("burgerMenu");    // Supprimer la class burgerMenu qui informe que le menu etait en Menu Burger
            osInstance.destroy();                                   // Destruction de la scrollbar des entrées du Menu Burger

            $(".ss_cat").removeAttr("style");       // Supprimer les styles inlines
            $(".objet_cat").removeAttr("style");    // Supprimer les styles inlines
            $(".gondole").removeAttr("style");    // Supprimer les styles inlines

            $(".hasSubCategories").removeClass("actif");    // Désactivation des Sous-Catégories ouvertes
            $(".hasObjets").removeClass("actif");           // Désactivation des  Objets ouverts
            $(".gondole_title").removeClass("actif");           // Désactivation des  Objets ouverts

            /* Si Menu Burger Ouvert => Cacher le Menu Burger et le shad burgermenu */
            if ($(".header_main_wrapper ").hasClass("actif")) {
                $('.header_main_wrapper').removeClass('actif');
                triggerShad('burgemenu', 'remove');
            }

            /* ---------- Passage MENU CLASSIQUE => MENU BURGER ---------- */
        } else if ($(window).width() <= 1423 && !$(".header_main_wrapper ").hasClass("burgerMenu")) {
            $(".header_main_wrapper").addClass("burgerMenu");  // Ajouter la class burgerMenu qui informe que le menu passe en Menu Burger
            osInstance = OverlayScrollbars(document.getElementById('scrollbar_burgerMenu'), {}); // Initialisation de la scrollbar des entrées du Menu Burger
        }
    });

    /* ___________________ PREHEADER+ HEADER ___________________ */
    /* __________________ Swiper Pre Header __________________ */
    if ($('.preheaderWrapper').length && ($('.promo_swiper .swiper-slide').length > 1)) {
        var preheader_swipe = new Swiper('.promo_swiper', {
            slidesPerView: 1,
            autoplay: true,
            loop: true,
            speed: 2000,
        })
    }

    /* -------------------- INPUT SEARCH HEADER -------------------- */
    /* Clique sur la barre recherche, ouverture du bandeau recherche et activation du shad search */
    $('#trigger_recherche').on('click touchstart', function (evt) {
        closePopin("#advantage_lightbox", "rollovermenu");                      // Fermer la lightbox avantage et cacher le shad
        closePopin(".header_item", "rollovermenu");                             // Fermer les rollovers ouverts et cacher le shad rollovermenu
        closePopin(".categ_item", "menu");                                      // Fermer les sous menus ouverts et cacher le shad menu

        $('.wrap_search_field').addClass('actif');
        triggerShad('search', 'add');
    });

    /* Clique sur la croix du bandeau de recherche ou sur le shad search ou appuyer sur la touche "enter" => Fermeture du bandeau de recherche et désactivation du shad search */
    function hideSearch() {
        $('.wrap_search_field').removeClass('actif');
        triggerShad('search', 'remove');
    }

    $('#closeSearchWrapper, #shad_search').on('click touchstart', hideSearch);

    $('#search_home').on('keypress', function (e) {
        // Si le shad est actif et si la touche pressée est la touche "enter" (code touche 13") on hideSearch()
        if (e.which == 13) {
            hideSearch();
        }
    });

    /* -------------------- LIGHTBOX CODE AVANTAGE -------------------- */
    /* Clique sur le bouton Code Avantage => Ouverture ou Fermeture de la lightbox Code Avantage */
    $('#advantage_code').on('click touchstart', function (evt) {
        closePopin(".header_item", "rollovermenu");                             // Fermer les rollovers ouverts et cacher le shad rollovermenu
        closePopin(".categ_item", "menu");                                      // Fermer les sous menus ouverts et cacher le shad menu
        closePopin(".container_lightbox_alert_stock", "rollovermenu")           // Fermer lightbox alert stock ouverte et cacher le shad rollovermenu             

        if (!$('#advantage_lightbox').hasClass('actif')) {
            triggerShad('rollovermenu', 'add');
            $('#advantage_lightbox').addClass('actif');
        }
    });

    /* Clique sur shad rollovermenu */
    $('#shad_rollovermenu').on('click touchstart', function (evt) {
        closePopin("#advantage_lightbox", "rollovermenu");                      // Fermer la lightbox avantage et cacher le shad
        closePopin(".header_item", "rollovermenu");                             // Fermer les rollovers ouverts et cacher le shad rollovermenu
        closePopin(".container_lightbox_alert_stock", "rollovermenu")           // Fermer lightbox alert stock ouverte et cacher le shad rollovermenu             
    });

    /* -------------------- COMPTE -------------------- */
    /* Au survol de la souris sur l'icône Compte Connecté */
    $("#account_top.connected").on("mouseenter touchstart", function (evt) {
        closePopin("#advantage_lightbox", "rollovermenu");                      // Fermer la lightbox avantage et cacher le shad
        closePopin(".container_lightbox_alert_stock", "rollovermenu")           // Fermer lightbox alert stock ouverte et cacher le shad rollovermenu             
    });

    /* -------------------- PANIER -------------------- */
    /* Au survol de la souris sur l'icône Panier */
    $("#cart_top").on("mouseenter", function () {
        closePopin("#advantage_lightbox", "rollovermenu");                      // Fermer la lightbox avantage et cacher le shad
        closePopin(".container_lightbox_alert_stock", "rollovermenu")           // Fermer lightbox alert stock ouverte et cacher le shad rollovermenu             
    });

    /* Scrollbar Listes des Produits Panier */
    $(".top_basket_wrapper").overlayScrollbars({
        scrollbars: {
            autoHide: "leave",
            autoHideDelay: 0,
        }
    });

    /* Clique sur shad menu */
    $('#shad_menu').on('click touchstart', function (evt) {
        closePopin(".categ_item", "menu");                                      // Fermer les sous menus ouverts et cacher le shad menu
    });

    /* -------------------- TABLETTE ROLLOVER -------------------- */
    displayRolloverItem("#cart_top");
    displayRolloverItem("#account_top.connected");

    /* ____________________ RAYON ____________________ */
    if ($("body.category .page_rayon").length) {
        // Modification de la clef de traduction du placeholder de chaque input Alert Stock
        $(".bloc_add_alert_form .w-input-element").each(function () {
            this.placeholder = Translator.translate('input_alert_stock');
        });

        // Modification de la clef de traduction du bouton d'envoi de  chaque input Alert Stock
        $(".bloc_add_alert_form .form_submit button").each(function () {
            $(this).addClass("button");
        });

        $(".wrapper_meta").overlayScrollbars({
            scrollbars: {
                autoHide: "leave",
                autoHideDelay: 0,
            }
        });
    }

    /** Au clique sur toute la page rayon ou la page recherche sauf sur les filtres => Fermeture des filtres ouverts **/
    $(document).click(function (e) {
        if (!$(e.target).closest('.filters_products').length) {
            $('.filters_products .filter_name.actif').removeClass('actif');
            $('.filters_products .selected_options.actif').removeClass('actif');
        }
    })

    /* ____________________ FAQ ____________________ */
    /* Scrollbar Résultat Recherche FAQ */
    $(".wrapper_faq .list_result").overlayScrollbars({
        scrollbars: {
            autoHide: "leave",
            autoHideDelay: 0,
        }
    });

    /**____________TUNNEL________________ **/
    /* Scrollbar produits Tunnel */
    $("#cart_total .total_produit_dropdown").overlayScrollbars({
        scrollbars: {
            autoHide: "leave",
            autoHideDelay: 0,
        }
    });

    $(".bill_line.used_cp_info").click(function () {
        $(this).toggleClass("open");
        $(".bill_line.used_cp_info span").slideToggle();
    });

    setTimeout(function () {
        if ($('#subtitleLogin')) {
            var title = $('#subtitleLogin').prev('h3');
            var text = title.text();

            // Remove <h3>
            title.remove();

            // Replace <h3> by <h2>
            $('<h2>'+ text + '</h2>').insertBefore($('#subtitleLogin'));
        }
    }, 250);
});

// count filters in rayon
function countItemFilter(input, translation) {
    var filterName = input.name;
    var filters = $('input:checked[name=' + filterName + ']');
    var countFilters = filters.length

    if (countFilters > 0) {
        if ($("#trigger_filtre [data-filtername='" + filterName + "']").length) {
            if ($("#trigger_filtre [data-filtername='" + filterName + "']").find('.count_filters').length) {
                $("#trigger_filtre [data-filtername='" + filterName + "'] .count_filters").html(' (' + countFilters + ')');
            } else {
                $("#trigger_filtre [data-filtername='" + filterName + "']").append('<span class="count_filters"> (' + countFilters + ') </span>');
            }
        } else if ($("#trigger_filtre [data-filtername='" + translation + "']").length) {
            if ($("#trigger_filtre [data-filtername='" + translation + "']").find('.count_filters').length) {
                $("#trigger_filtre [data-filtername='" + translation + "'] .count_filters").html(' (' + countFilters + ')');
            } else {
                $("#trigger_filtre [data-filtername='" + translation + "']").append('<span class="count_filters"> (' + countFilters + ') </span>');
            }
        }
    } else {
        if ($("#trigger_filtre .filtre_elem .count_filters").length) {
            $("#trigger_filtre .filtre_elem .count_filters").remove();
        }
    }
}
