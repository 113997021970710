/**
 * Pour la gestion de la page faq.php
 * @author Aurélien Renault
 * @since 2019/03/08
 */

/** Fonction pour la mise en place des event
 Paramamètres :
 array_questions => tableau des réponses de la faq
 */
function faqinit(array_questions) {
    /* ------------------- Event l'input de recherche faq ------------------- */
    $(document).on('change keyup focus', '#faq_search', function () {
        showTabFaq();  // Appel de la fonction pour réinitialiser l'affichage 

        var search = $(this).val();   // Valeur du champ de l'input

        /** Si le champ contient au moins 4 caractères **/
        if (search && search.length > 3) {
            $('.search_results li').show().removeClass("hasMatch");

            var array_match = [];

            /* On affiche uniquement celles qui correspondent à la recherche */
            $.each(array_questions, function (i, val) {
                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());	 // On stocke la question si contient une partie de la saisie ou null
                /* Pour chaque question de la listen si elle matche avec la recherche, on affiche le bloc des résultats et les questions qui ont matchées */
                if (match) {
                    array_match.push(match);
                    $('.search_results').show();
                    $('li#brand_' + val['id']).addClass("hasMatch");
                }
            });

            $('.brand_item:not(.hasMatch)').hide(); // On cache les questions qui n'ont pas matché avec la question

            /* Si pas de résultats */
            if (array_match.length == 0) {
                $('.search_results').hide(); // Cacher le bloc des résultats 
            }

            /** Si le champ de recherche est non rempli ou s'il contient moins de 4 caractères, on cache le bloc des résultats**/
        } else {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });

    /* -------------------Event sur le container des résultats ------------------- */
    var click_in_process = false;
    /***  Au maintien du clique sur le container des résultats ***/
    $('.search_results').mousedown(function () {
        click_in_process = true;
    });

    /***  Au relâchement du clique sur le container des résultats ***/
    $('.search_results').mouseup(function () {
        click_in_process = false;
        $('#faq_search').focus(); // Remettre le focus sur l'input
    });

    /*** Lorsqu'on perd le focus de l'input de recherche, cacher le bloc des résultats ***/
    $('#faq_search').blur(function () {
        if (!click_in_process) {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
        }
    });
}

/* Fonction pour la gestion de foctionnement du thème
Paramamètres : 
    node_id => id du thème de la question sélectionnée
    level =>   
    question_id => id de la question séléctionnée
*/
function showTabFaq(node_id, level, question_id) {
    /*** Appel de la fonction avec un id d'une question séléctionnée  (Clique sur une question des résultats de recherche ou d'une question d'un thème ouvert, pour la gestion d'une question spécifique) ***/
    if (question_id !== null && question_id !== undefined) {
        /** Désactiver et Cacher toutes les questions et les réponses sauf la question et la réponse avec l'id définit */
        $(".faq_question:not(.faq_question_" + question_id + ")").removeClass("active");
        $(".faq_reponse:not(.faq_reponse_" + question_id + ")").slideUp("fast", function () {
                $(this).addClass("cache");
            }
        );
        /** Activer ou désactiver la question avec l'id de la question et afficher ou cacher la réponse avec l'id de la question **/
        $(".faq_question_" + question_id).toggleClass("active");
        $(".faq_reponse_" + question_id).slideToggle("slow", function () {
            $(this).toggleClass("cache");
        });

        /** Scroll Haut de page Positionnement du thème ouvert  **/
        setTimeout(function () {
            $("html, body").animate({scrollTop: $(".faq_element.active").offset().top - 180}, 500);
        }, 250);

        /*** Appel de la fonction sans id d'une question (Clique sur un thème) ***/
    } else {
        /** Désactiver tous les thèmes & Activer le thème séléctionné **/
        $(".faq_element:not(.faq_theme_" + node_id + ")").removeClass("active");
        $(".faq_theme_" + node_id).toggleClass("active");

        /** Cacher toutes le bloc des questions des thèmes désactivés **/
        $(".faq_questions:not(.faq_questions_" + node_id + ")").slideUp("fast", function () {
            $(this).addClass("cache");
        });

        /** Cacher toutes les réponses des thèmes désactivés **/
        $(".faq_questions:not(.faq_questions_" + node_id + ") .faq_reponse").slideUp("fast", function () {
            $(this).addClass("cache");
        });

        /** Afficher ou Cacher les questions du thème sélectionné **/
        $(".faq_questions_" + node_id).slideToggle("fast", function () {
            $(this).toggleClass("cache");
        });

        /** Si thème sélectionnée, scroll vers le thème qui vient d'être ouvert **/
        if (node_id !== undefined) {
            setTimeout(function () {
                $("html, body").animate({scrollTop: $(".faq_element.active").offset().top - 180}, 500);
            }, 250);
        }
    }
}

/* Fonction pour afficher la question séléctionné dans les résultats de la recherche
Paramamètres : 
    node_id => id du thème de la question sélectionnée
    level =>   
    question_id => id de la question séléctionnée
*/
function researchFaq(node_id, level, question_id) {
    $('.search_results').hide(); // Cacher le bloc des résultats de la recherche

    if (!$(".faq_theme_" + node_id + "").hasClass("active")) { // Si thème pas ouvert, affichage du thème de la question.
        showTabFaq(node_id, level);
    }

    showTabFaq(node_id, level, question_id); // Appel de la fonction pour faire afficher la question séléctionnée
}