/* Fonction pour ouvrir une lightbox spécifique à un id
    Paramamètres :
        id => nom de l'id de la lightbox à ouvrir
*/
function openMultiShad(id) {
    if ($('.lightbox.actif').length) {
        $('.lightbox.actif').removeClass('actif'); // Fermer toutes les lightbox qui sont ouvertes
    }
    $("#" + id).addClass('actif'); // Ouvrir la lightbox ciblée par l'id
    if (!$("#shad").hasClass('actif')) {
        $("#shad").addClass('actif'); // Afficher le shad si pas actif
    }
    $("#shad").on("click", function () { // Au clique sur le shad, appel de la fonction closeMultiShad pour fermer la lightbox et cacher le shad
        closeMultiShad(id);
    });

    if (id == "zoomBox") {
        $("#shad").addClass("shad_zoom");

        $('#zoom_box_wrapper .swiper-slide').mousemove(function () {
            var slideOffset = $(this).offset();
            var relY = -1 * (event.pageY - slideOffset.top);
            $(this).find('img').css('top', relY + 0 + "px");
        });

    }
    if (id == "mention_legales_pop_up") {
        $("#shad").addClass("shad_all");
    }
}

/* Fonction pour fermer une lightbox spécifique à l'id
    Paramamètres :
        id => nom de l'id de la lightbox à fermer
*/
function closeMultiShad(id) {
    $("#" + id).removeClass('actif'); // Fermerture de la lightbox ciblée par l'id
    $("#shad").removeClass('actif');  // Cacher le shad
    if (id === "exit_overlay_OVI") {  // Si lightbox OVI, on cache le shad et la lightbox avec hide();
        $("#shad").hide();
        $("#exit_overlay_OVI").hide();
    }

    if (id == "zoomBox") {
        $("#shad").removeClass("shad_zoom");
    }

    if (id == "mention_legales_pop_up") {
        $("#shad").removeClass("shad_all");
    }
}

/**
 * Permet de récupérer les stocks des tailles par couleur ainsi que leur stock
 * @return  void
 */
function setAvailableSizes() {
    if (window.json_colisages !== undefined) {
        var sizes_tab = window.json_colisages[$(this).val()];
        var size_box, is_disabled;
        var has_stock = false;

        for (size_id in sizes_tab) {
            if (sizes_tab.hasOwnProperty(size_id)) {
                size_box = $('.product_main_wrapper [name="itm_size"]').filter('[value="' + size_id + '"]');
                is_disabled = !sizes_tab[size_id];
                if (is_disabled) {
                    size_box.addClass('disabled');
                } else {
                    size_box.removeClass('disabled');
                    has_stock = true;
                }
            }
        }
        if (has_stock) {
            $("#btn_add_cart").show();
            $('.eclat_product_nouveaute_fiche_produit').hide();
            $(".eclat_product_epuise_fiche_produit").hide();
        } else {
            if ($('#case_ajax') !== 'undefined') {
                if ($('#case_ajax').val() == '5' || $('#case_ajax').val() == '6' || $('#case_ajax').val() == '7') {
                    $(".eclat_product_nouveaute_fiche_produit").removeClass('cache').show();
                    $(".eclat_product_epuise_fiche_produit").hide();
                    $(".eclatProd").show().removeClass('cache');

                    $("#bloc_add_alert").removeClass('cache').show();
                    $("#btn_add_cart").hide();
                } else if ($('#case_ajax').val() == '8') {
                    $(".eclat_product_epuise_fiche_produit").removeClass('cache').show();
                    $(".eclat_product_nouveaute_fiche_produit").hide();
                    $(".eclatProd").hide();

                    $("#btn_add_cart").hide();
                    $("#bloc_add_alert").hide();
                } else { // Case 1 / 2 / 3 / 4
                    $(".eclat_product_epuise_fiche_produit").hide();
                    $(".eclat_product_nouveaute_fiche_produit").hide();
                    $(".eclatProd").show().removeClass('cache');

                    $("#btn_add_cart").removeClass('cache').show();
                    $("#bloc_add_alert").hide();
                }
            }
        }
    }
}

var elmt;
var loader;
var prev_display;

// Shows button's underlying loader and triggers defined action
function waitingProcess(loader, action, id_form) {
    var elmt = $(this),
        loader = $(loader);

    prev_display = elmt.css("display");

    if (!loader.parent().hasClass("loading")) {
        loader.parent().addClass("loading");

        switch (action) {
            case 0:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuthTop,
                    1,
                    path_relative_root
                );
                break;

            case 1:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwdTop,
                    1,
                    path_relative_root
                );
                break;

            case 2:
                window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                break;

            case 3:
                window.setTimeout(function () {
                    checkAllForm(tabError, 0, path_relative_root, id_form);
                    loader.parent().removeClass("loading");
                }, 1000); // attention ne pas modifier le temps 1000 pour que le intelinput ai le temps de bien remplir les données
                break;

            case 4:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuth,
                    1,
                    path_relative_root
                );
                break;

            case 5:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwd,
                    1,
                    path_relative_root
                );
                break;

            case 6:
                window.setTimeout(checkFormParrainage, 1000);
                break;

            case 7:
                if (
                    checkAllForm(
                        tabErrorGrattez,
                        0,
                        path_relative_root,
                        id_form
                    )
                ) {
                    window.setTimeout(processGrattezGagnez(), 1000);
                }
                break;
        }
    }
}

function hideLoader() {
    if (loader) {
        loader.css("display", "none");
        loader
            .removeAttr("style")
            .parent()
            .removeClass("loading");
    }
    if (elmt) {
        elmt.css("display", prev_display);
    }
}

function waitingAddToBasket(evt, form_elm, from, itm_rr_id) {
    var id = '';
    var produit_id = form_elm.getValue('produit_id');
    var produit_refext = form_elm.getValue('produit_refext');
    var suff = form_elm.getValue('suff');
    var panier_id = form_elm.getValue('panier_id');
    var titreObjet = form_elm.getValue('titreObjet');
    var idTaille = form_elm.getValue('idTaille');
    var lastTaille = form_elm.getValue('lastTaille');
    var idCouleur = form_elm.getValue('idCouleur');
    var lastCouleur = form_elm.getValue('lastCouleur');
    var toEval = form_elm.getValue('toEval');
    var page_name = form_elm.getValue('page_name');
    var path_web = form_elm.getValue('path_web');
    var enable_omniture = form_elm.getValue('enable_omniture');
    var is_not_produit = form_elm.getValue('is_not_produit');
    var is_lightbox_specifique = form_elm.getValue('is_lightbox_specifique');
    var is_achat_express = $('#is_achat_express_v2').val() == 1;

    if ($("div[data-produit-id=" + produit_id + "]").hasClass("wrapperProdVertical")) {
        is_achat_express = true;
    }
    var loader;
    var prix = form_elm.getValue('prixU');
    var qte = form_elm.getValue('qteProd');

    var productBatchId = $('input[name="unit_lot"]:checked').data('productid');
    var priceTag = $('#productBasket' + produit_id + ' .item_price .pricetag').html();

    if (!is_achat_express && productBatchId != produit_id && productBatchId != undefined) {
        titreObjet = $('input[name="unit_lot"]:checked').data('producttitle');
        prix = $('input[name="unit_lot"]:checked').data('productprice');
        qte = $('input[name="unit_lot"]:checked').data('productbatchnumber') * qte;
        priceTag = $('#productBasket' + productBatchId + ' .item_price .pricetag').html();
    } else {
        productBatchId = '';
    }

    if (!is_achat_express) {
        evt.preventDefault();
    }

    if (from === undefined) {
        from = '';
    }

    if (is_not_produit == true) {
        id += '_' + produit_id;
    }

    if (this.nextElementSibling && this.nextElementSibling.classList.contains('loader')) {
        loader = $(this.nextElementSibling);
        loader.addClass("loading");
    } else {
        $("#bloc_btn_active" + id).attr('class', 'cache');
        $("#bloc_btn_loader" + id).attr('class', 'f_right');
    }

    if (is_lightbox_specifique == 'true') {
        add_produit_specifique(produit_id, idCouleur, idTaille);
    } else {
        window.setTimeout(addToBasket, 1000, produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id, prix, qte, productBatchId, priceTag);
    }
}

function addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id, prix, qte, productBatchId, priceTag) {
    var id = '';
    prix_id = 'prixU';

    if ((from == 'basket' || is_not_produit == true || is_achat_express) && itm_rr_id !== undefined && itm_rr_id !== null) {
        id += '_' + produit_id + itm_rr_id;
        prix_id = 'prixU' + id;
    } else if (from == 'basket' || is_not_produit == true || is_achat_express) {
        id += '_' + produit_id;
        prix_id = 'prixU' + id;
    }

    var prixElement = document.getElementById(prix_id);
    if (!prixElement) {
        return;
    }

    var array_panier = newUpdatePanier(produit_id, suff, panier_id, prixElement.value, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, qte, productBatchId);

    if (array_panier != false) {
        if (from == 'basket') {
            location.reload();
            return false;
        }

        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0) {
            type = 'err';
        }

        omnitureEvent = '';
        if (array_panier[1] == 1) {
            omnitureEvent = 'scOpen,scAdd';
        } else {
            omnitureEvent = 'scAdd';
        }

        if (loader) {
            loader.removeClass('loading');
        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }

        if (array_panier[1] > 0 && enable_omniture == true) {
            void (s.t());
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void (s.t());
        }

        if (array_panier.length >= 0 && array_panier[5] !== undefined) {
            let dl_objet = array_panier[5];
            if (is_achat_express) {
                let new_dl_objet = JSON.parse(dl_objet)
                new_dl_objet.ecommerce.is_achat_express = true;
                dl_objet = JSON.stringify(new_dl_objet);
            }

            pushIntoDatalayer(dl_objet);
        }

        // Mise à jour du panier top
        var response_basket = ajax_file(path_web + create_link('ajax_show_basket'));

        $("#cart_top").html(response_basket);

        $("#cart_top .cart_product_listing").overlayScrollbars({
            scrollbars: {
                autoHide: "leave",
                autoHideDelay: 0,
            }
        });

        newshowmodal(produit_id, is_not_produit, titreObjet, priceTag, productBatchId);
    } else {
        if (loader) {
            loader.parent().removeClass('loading');
        } else {
            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }
    }

    eval(toEval);

    if (array_panier != false) {
        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {
            var svn = document.getElementsByTagName("SELECT");
            for (a = 0; a < svn.length; a++) {
                svn[a].style.visibility = "hidden";
            }
        }
    }

    return false;
}

/***** FUNCTION PUSH INTO DATALAYER  *****/

function pushIntoDatalayer(data) {
    if (typeof (data) == 'object') {
        window.dataLayer.push(data);
    } else if (typeof (data) == 'string') {
        window.dataLayer.push(JSON.parse(data));
    }
}

function dataLayer_associated_product() {
    if (window.$('#gondole_aimerez')) {
        var product_id = this.getAttribute('data-prod') ? event.currentTarget.getAttribute('data-prod') : '';
        var productObj = null;

        if (product_id !== '') {
            if (typeof data_layer_products != 'undefined' && data_layer_products.length > 0) {
                data_layer_products.forEach(function (item) {
                    if (item.id == product_id) {
                        productObj = item;
                    }
                })
            } else if (typeof data_layer_products_aimerez != 'undefined') {
                var objs = Object.keys(data_layer_products_aimerez);
                var values = Object.values(data_layer_products_aimerez);

                for (var i = 0; i < objs.length; i++) {
                    if (values[i].id == product_id) {
                        productObj = values[i];
                    }
                }
            }

            var prod = productObj;

            if (productObj !== null && productObj !== undefined) {
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': {'list': productObj['category']}, // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': "function () {document.location = productObj.url}"
                });
            }
        }
    }
}

function changeVisu(field, path, evt) {
    var new_img, target, current;

    field = document.getElementById(field);

    if (evt) {
        target = evt.target.parentElement;
        current = target.parentElement.getElementsByClassName("current");

        target.classList.add("current");

        Array.prototype.slice.call(current).forEach(function (elm) {
            if (elm !== target) {
                elm.classList.remove("current");
            }
        });
    }

    if (field && field.parentElement) {
        new_img = new Image();

        new_img.id = field.id;
        new_img.className = field.className;

        new_img.addEventListener("load", function () {
            this.alt = field.alt;

            this.setAttribute("width", this.naturalWidth);
            this.setAttribute("height", this.naturalHeight);

            if (field.parentElement) {
                field.parentElement.appendChild(this);
                field.parentElement.removeChild(field);
            }
        });

        new_img.src = path;
    }
}

function showpdv(is_find_store) {
    $('.btnFindRetailer').addClass('loading');
    var shad, modbox, contents;
    $('html, body').animate({
        scrollTop: 0
    }, 500);

    $.ajax({
        type: 'post',
        url: path_relative_root + create_link('ajax_search_shop'),
        success: function (res) {
            if (res) {
                $('.btnFindRetailer').removeClass('loading');
                shad = document.getElementById('shad');
                modbox = document.getElementById('pdvBox');

                contents = $('<div />').html(res);
                $('#pdvBox').removeClass('deployed');

                // Make sure modbox will show on top of shad
                $(shad).after(modbox);

                // Inject store locator into modbox
                $('.store_locator', modbox).html($('.storeloc_form', contents).add($('.liste_distributeur_wrapper', contents)));

                var listeMag = document.getElementById('liste_distributeur');
                listeMag.innerHTML = '';

                //wording
                if (is_find_store) {
                    //Ajout-suppression de classe sur le conteneur pour différencier les css entre : le trouver en magasin/Choisir magasin préféré
                    $('#pdvBox').addClass('find_art_in_store');
                    $('#pdvBox').removeClass('choose_fav_mag');
                } else {
                    $('#pdvBox').addClass('choose_fav_mag');
                    $('#pdvBox').removeClass('find_art_in_store');
                }

                // On va rajouter le filtre pour n'avoir que les magasin avec le comptoir pour les PDV
                // On doit le rajouter en JS car on utilise la même vue avec la recherche magasin
                if (!document.getElementById('filter_pdv')) {
                    var caratactestiques = [];

                    if (document.getElementById('produit_id')) {
                        var productId = document.getElementById('produit_id').value;
                    }
                    if (document.getElementById('produit_caracterstiques')) {
                        var is_caracteristique = document.getElementById('produit_caracterstiques');
                    }
                }

                // magasin_gmap.js
                initialize(true);

                $(modbox).addClass('actif');
                $(shad).addClass('actif');

                // Show modbox and make sure closing routine won't fire more than once
                $(shad).off('click', closepdv).fadeIn(200, function () {
                    $(modbox).slideDown(600);
                    $(shad).on('click', closepdv);
                    $('#content_pdvBox_new').hide();
                    $('#content_pdvBox').show();
                    $(modbox).removeClass('showForm');
                    $(modbox).removeClass('thanks');
                });
            } else {
                $('.btnFindRetailer').removeClass('loading');
            }
        }
    });
}

function closepdv() {
    var shad = document.getElementById('shad');
    var modbox = document.getElementById('pdvBox');

    if ($(".helloCustomer:visible").length) {
        $(modbox).removeClass('actif');
        $(shad).removeClass('actif');
        location.reload();
    } else {
        $(modbox).removeClass('actif');
        $(shad).removeClass('actif');
    }
}

/*
    Permet l'envoi du mail qui confirme l'enregistrement de sont email,
    pour alerter la personne quand le produit sera disponible
*/
function sendMailAlertStock(type_alert, form, itm_rr_id) {
    var id = form.getValue('produit_id'); // id du produit
    var is_achat_express = $('#is_achat_express_v2').val() !== undefined; // si on est en achat express
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';       // l'id du regroupement
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';    // Si achat express, on regroupe id du produit et id de regroupement
    var inputEmail = form.elements['mail_alerte_stock'],        // input du mail
        email = inputEmail.value,                               // email renseigné dans l'input
        produit_id = $('#produit_principal' + elem).val(),      // id du produit
        taille_id = $("#tailleProd" + elem).val(),              // id de la taille
        couleur_id = $("#couleurProd" + elem).val(),            // id de la couleur
        mailformat = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i; // REGEX pour les mails
        
    if (email === '' || !email.match(mailformat)) {
        $('#mail_alerte_stock' + elem).addClass('inputErr');
        $('.bloc_add_alert_erreur').show();
    } else {
        $('#mail_alerte_stock' + elem).removeClass('inputErr');
        $('.bloc_add_alert_erreur').hide();

        $.ajax({
            type: 'post',
            url: path_relative_root + create_link('ajax_mail_alert_stock'),
            data: {
                email: email,
                produit_id: produit_id,
                taille_id: taille_id,
                couleur_id: couleur_id,
                type_alert: type_alert
            },
            success: function (response) {
                $('.bloc_add_alert' + elem + ' .alert_stock').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').hide();
                $('.bloc_add_alert' + elem + ' .stock_alert_description').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').show();
                $('.bloc_add_alert' + elem + ' .alert_return').show();
            },
            fail: function () {
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').show();
                $('.bloc_add_alert' + elem + ' .stock_alert_description').show();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').show();
            }
        });
    }
}

/**
 * Tunnel
 */

function newshowmodal(products, type, root, id_produit, is_not_produit) {
    topCartAppear();
    window.setTimeout(function () {
        topCartDesappear();
    }, 2000);
}

function topCartAppear() {
    var cart_top = $('#cart_top');
    cart_top.addClass('actif')

    $('#show_top_cart #order_basket_list').overlayScrollbars({
        scrollbars: {
            autoHide: "leave",
            autoHideDelay: 0,
        }
    });

    $('#shad_menu').addClass("actif");
}

function topCartDesappear() {
    var cart_top = $('#cart_top');

    cart_top.removeClass('actif')
    $('#shad_menu').removeClass("actif");
}

window.onload = function () {
    // Initialize Sliders
    var sliderSections = document.getElementsByClassName("range-slider");
    for (var x = 0; x < sliderSections.length; x++) {
        var sliders = sliderSections[x].getElementsByTagName("input");
        for (var y = 0; y < sliders.length; y++) {
            if (sliders[y].type === "range") {
                sliders[y].oninput = getValsFilterPriceRayRange;
                sliders[y].oninput();
            }
        }
    }
    getwishlistrayon();
}

function getwishlistrayon() {
    let array_elements = document.querySelectorAll('a[data-productid]');
    let array_ids = [];
    if (typeof array_elements != 'undefined' && array_elements.length > 0) {
        for (el of array_elements) {
            var id = el.getAttribute('data-productid');
            if (id != '' && id != 'undefined') {
                array_ids.push(id);
            }
        }
        let data = JSON.parse((JSON.stringify(Object.assign({}, array_ids))));
        $.ajax({
            type: 'get',
            url: path_relative_root + create_link("get_user_all_wishlist"),
            data: data,
            success: function (response) {
                if (response) {
                    if (typeof response != 'undefined' && response.length > 0) {
                        let array_product_id = JSON.parse(response);
                        for (product_wishlist in array_product_id) {
                            $('a[data-productid = "' + array_product_id[product_wishlist].fk_produit_id + '"]').addClass("existToWishlistButton");
                            $('a[data-productid = "' + array_product_id[product_wishlist].fk_produit_id + '"]').attr('data-wishlistproductid', array_product_id[product_wishlist].wishlist_product_id);
                        }
                    }
                }
            }
        });
    }
}

var rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
var rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
var rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");

// Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents(str) {
    return str.replace(rex_php_tags, "");
}

// Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents(str) {
    return str.replace(rex_script_tags, "");
}

// Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
function stripHTMLComments(str) {
    return str.replace(rex_comments_tags, "");
}

function stripCombo(str) {
    var output = stripHTMLComments(str);

    output = stripJSContents(output);
    output = stripPhpContents(output);

    return output;
}

/**
 * Loads a product and updates the UI with the new product information.
 *
 * @param {number} new_id - The ID of the new product to load.
 * @param {string} type - The type of the product.
 * @param {string} page - The page type where the product is being loaded.
 * @param {string} itm_rr_id - The item reference ID.
 * @param {string} triggerType - The type of trigger that initiated the product load (e.g., 'colorChange', 'sizeChange').
 * @param {boolean} [is_regroup=true] - Whether to regroup the product information.
 */
function loadProduct(new_id, type, page, itm_rr_id, triggerType, is_regroup = true) {
    var form = this;
    var old_id = this.getValue('produit_id');
    var cpt = this.getValue('cpt');
    var couleur_id = this.getValue(this.getValue('idCouleur'));
    var taille_id = this.getValue(this.getValue('idTaille'));
    var act = this.getValue('act');
    var qte_itm = 0;
    triggerType = (triggerType !== undefined) ? triggerType : '';

    var is_diffuseur = this.getValue('is_diffuseur');
    if (is_diffuseur == '1') {
        couleur_id = $('.prodColor:checked').val();
        taille_id = $('.prodSize:checked').val();
    }

    //Get original width and height of changed item, preserving style
    var oldModel = $(this).attr('id') !== '' ? $(this).attr('id').replace('prod_info_', '#itm-') : '',
        newModel = oldModel !== '' ? oldModel.replace('_', '-') : '',
        newWidth = $(newModel).css('width');

    if (this.elements.namedItem('qteProd')) {
        qte_itm = this.elements.namedItem('qteProd').value;
    }

    var is_model = this.getValue('hdn_is_model');
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express = $('#is_achat_express_v2').val() == 1;

    var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';
    var data = {
        produit_id: old_id,
        produit_principal: old_id,
        couleurProd: couleur_id,
        tailleProd: taille_id,
        act: act,
        qteProd: qte_itm,
        hdn_product_id: old_id,
        hdn_is_model: is_model,
        produit_id_new: new_id,
        type: type,
        type_page: (page !== undefined && page !== '') ? page : 'product',
        is_achat_express: is_wishlist ? 1 : 0,
        is_achat_express_v2: is_achat_express && !is_wishlist ? 1 : 0,
        cpt_prod: cpt_prod - 1
    };

    if (is_wishlist) {
        data.wishlist = true;
    }

    $.ajax({
        type: 'post',
        url: path_relative_root + create_link('ajax_get_product_regroup_ref'),
        data: data,
        success: function (res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error('parseJSON');
                return;
            }

            if (res.success == 'ok') {
                var datas = res.datas;

                if (data.type_page == 'product') {

                    var $html_main = $(stripCombo(datas.html_main));

                    var prod_assoc = $('#assocs_slider', $html_main);
                    var color = $("#ligne_couleur").html();

                    var main_wrapper = $('.product_main_wrapper', $html_main);
                    var info_wrapper = $('.product_info_wrapper', $html_main);
                    var zoom_box_wrapper = $('#zoom_box_wrapper', $html_main);
                    var zoom_thumb_wrapper = $('.zoom_main_wrapper .thumb_wrapper', $html_main);

                    if (data.is_achat_express_v2) {
                        if (is_regroup) {
                            // Photo et information
                            var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_', '-') : '');
                            $('#itm-' + itm_id).replaceWith($html_main);

                            var new_itm_id = new_id + (itm_rr_id !== '' ? itm_rr_id.replace('_', '-') : '');

                            var margin_right = $('#itm-' + new_itm_id).next().css('margin-right');
                            $('input.color_' + new_id + itm_rr_id).prop('checked', false);

                            $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);

                            initAchatExpressSwipers();
                        }
                        getwishlistrayon();

                        // 5FIVE SPECIAL CASE INTEGRATION : Once you have selected a color, in achat_express_v2 --> Display sizes
                        if (triggerType === 'colorChange') {
                            // Hide colors if visible
                            if ($("#ligne_couleur_" + (new_id + (itm_rr_id !== '' ? itm_rr_id : ''))).is(':visible')) {
                                $("#ligne_couleur_" + (new_id + (itm_rr_id !== '' ? itm_rr_id : ''))).fadeOut(600);
                            }
                            // Display sizes right after
                            setTimeout(function () {
                                $("#ligne_pointure_" + (new_id + (itm_rr_id !== '' ? itm_rr_id : ''))).fadeIn(600);
                            }, 600)
                        } else if (triggerType === 'sizeChange') {
                            // On size change -> be sure the product is available
                            $('#tailleProd_' + (new_id + itm_rr_id)).val(taille_id);

                            // Depending on the color, we check if the product is available -> if getAvailableQuantity returns true, we add to the cart
                            if (getAvailableQuantity(new_id, qte_itm, 'product', data.couleurProd, itm_rr_id)) {
                                waitingAddToBasket(null, document.getElementById('prod_info_' + new_id + itm_rr_id), '', itm_rr_id);
                            } else {
                                // If getAvailableQuantity renvoi false == no stock, on affiche l'alerte stock;
                                if ($("#ligne_pointure_" + (new_id + (itm_rr_id !== '' ? itm_rr_id : ''))).is(':visible')) {
                                    $("#ligne_couleur_" + (new_id + (itm_rr_id !== '' ? itm_rr_id : ''))).is(':visible').fadeOut(600);
                                }

                                // If no stock, show alert stock and hide add to basket btn
                                $("#bloc_add_color_" + (new_id + (itm_rr_id !== '' ? itm_rr_id : ''))).hide();
                                $("#bloc_add_alert_" + (new_id + (itm_rr_id !== '' ? itm_rr_id : ''))).show();
                            }
                        }
                    } else if (is_wishlist) {
                        // Photo and information
                        $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                        // Description composition, delivery, size guide
                        $('.product_feats_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());
                    } else {
                        var $html_middle = $(stripCombo(datas.html_middle));

                        var contents = $('<div />').html($html_main);

                        var video = $('.wrapper_video', contents);
                        $('.product_main_wrapper', '#site_global_wrap').html(main_wrapper.html());
                        $('.product_main_wrapper', '.product_info_wrapper').html(main_wrapper.html());

                        if (prod_assoc.length > 0) {
                            $('#assocs_slider', '#site_global_wrap').after(prod_assoc).remove();
                        }

                        // depending on triggerType, update either color checked or size selected
                        if (triggerType == 'sizeChange') {
                            $('input.prodSize').prop('checked', false);
                            $('input#size_' + data.tailleProd).prop('checked', true);
                        }

                        if (triggerType == 'colorChange') {
                            if (is_diffuseur == '1') {
                                $('#color_list_wrapper .prodColor').prop('checked', false);
                                $('#color_list_wrapper .prodColor#color_' + data.couleurProd).prop('selected', true);
                            } else {
                                $('#couleurProd').prop('selected', false);
                                $('#couleurProd option#color_' + data.couleurProd).prop('selected', true);
                            }
                        }

                        $('#videoBox', '#site_global_wrap').remove();
                        $('#shade').after(video);
                        if (document.getElementById("alma-widget") !== null) {
                            initAlmaWidget();
                        }

                        $('.breadcrumb.current').html('<span>' + datas.product.titreobjet + '</span>');

                        //zoom box
                        $('#zoom_box_wrapper').html(zoom_box_wrapper.html());
                        $('.zoom_main_wrapper .thumb_wrapper').html(zoom_thumb_wrapper.html());

                        getAvailableQuantity.call($('form.product_info', '#site_global_wrap').get(0), data.produit_id_new, data.qteProd, '', data.couleurProd, itm_rr_id);

                        if (is_diffuseur != '1') {
                            if ($('.product_page #ligne_couleur').length) {
                                $('#ligne_pointure').insertBefore($('.description_courte'));
                                $('#ligne_pointure').addClass('visible');
                            }
                        }

                        // On product load, add class on checked input (size)
                        checkAddBasket();
                    }

                } else if (data.type_page == 'assoc') {
                    if (data.is_achat_express_v2) {
                        // Photo and information
                        var $html_main = $(stripCombo(datas.html_main));
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_', '-') : '');

                        $('#itm-' + itm_id).replaceWith($html_main);
                        $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                    }
                }

                if (typeof $('#' + old_id + '-' + cpt) !== "undefined") {
                    $('#' + old_id + '-' + cpt).attr("id", datas.product.id + "-" + cpt);
                }

                checkProductInWishlist(datas.product.id, itm_rr_id);

                if (wShop.$refs.wAlertestockForm) {
                    wShop.$refs.wAlertestockForm.reloadBloc += 1;
                }

                $('.thumbslide .see_more').on('click', function (e) {
                    $('.thumbslide').removeClass('active');
                    $(this).parents('.thumbslide').addClass('active');
                });

            } else {
                $('#btn_error').fadeIn();
            }
        },
        complete: function () {
            var heads = $("[data-tab-head]");
            var containers = $("[data-tab-container]");

            if (window.YT_Player !== undefined) {

                window.YT_Player.destroy();

                window.YT_Player = undefined;
                window.YT_vid_id = undefined;
            }

            if (is_wishlist) {
                // event sur le bouton d'ajout à la wishlist
                $("#addToWishlistButton").on("click", addToWishlist);
            }

            //Simulates scroll (2px) to load images
            if (!$('.product_page').length) {
                window.scrollTo(0, $(window).scrollTop() + 2);
            }

            setTimeout(function () {
                // Resetting alert stock field
                $('.bloc_add_alert_form').hide();
            }, 250);

            //Swiper TG home and associated products: no swiper loading, adding the swiper-slide class for integration
            //Allows to maintain the integration of the swiper
            if ($(".homepage").length) {

                if ($('.swiperTg').length) {
                    var model = $('.swiperTg').find($('.swiper-slide'));
                } else if ($('.alt_sld_view').length) {
                    var model = $('.alt_sld_view');
                }

                var item = $('.item:not(.swiper-slide)'),
                    newItm = '#itm-' + new_id + (itm_rr_id !== '' ? itm_rr_id.replace('_', '-') : '');

                item.css({
                    width: $(model).css('width'),
                    height: $(model).find('imgProd').css('height')
                })

                $(item).find($('.swiper-slide-off')).css('width', '100%');
                item.addClass('swiper-slide');

            }

            //Swiper recently viewed on product page: load lazyload on color change to display the image
            if ($(".product_page").length) {
                var model;
                var new_itm_id = new_id + (itm_rr_id !== '' ? itm_rr_id.replace('_', '-') : '');

                resetThumbSwiperFP();

                /* PRODUITS ASSOCIES */
                // Produits complementaires *********************************************
                if (($('#complementary_product').length)) {
                    if ($("#gondole_aimerez .swiper-wrapper>.swiper-slide").length > 4) {
                        if ($('.complementary_product_pagi').length && !$('.complementary_product_pagi').is(':visible')) {
                            $('.complementary_product_pagi').show();
                        }
                        var productThumbsaa = new Swiper('#gondole_aimerez', {
                            loop: false,
                            slidesPerView: 4,
                            slidesPerGroup: 4,
                            spaceBetween: 10,
                            navigation: {
                                nextEl: '.swiper-button-nexta',
                                prevEl: '.swiper-button-preva',
                            },
                        });
                    } else {
                        $("#gondole_aimerez").addClass('no_swiper');

                        if ($('.complementary_product_pagi').length) {
                            $('.complementary_product_pagi').hide();
                        }
                    }
                }

                // Produits similaires *********************************************
                if ($("#gondole_complete .swiper-wrapper>.swiper-slide").length > 4) {

                    if ($('.similar_product_pagi').length && !$('.similar_product_pagi').is(':visible')) {
                        $('.similar_product_pagi').show();
                    }
                    var productThumbsa = new Swiper('#gondole_complete', {
                        spaceBetween: 10,
                        loop: false,
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                    });
                } else {
                    $("#gondole_complete").addClass('no_swiper');

                    if ($('.similar_product_pagi').length) {
                        $('.similar_product_pagi').hide();
                    }
                }

                /* PRODUITS RECEMMENT CONSULTES */
                if ($('#productVisitedSwiper .swiper-wrapper>.assoc').length > 5) {
                    var productThumbsaa = new Swiper('#productVisitedSwiper', {
                        spaceBetween: 5,
                        loop: false,
                        slidesPerView: 5,
                        navigation: {
                            nextEl: '#recent_see .swiper-button-next',
                            prevEl: '#recent_see .swiper-button-prev',
                        },
                        scrollbar: {
                            el: '#recent_see .swiper-scrollbar',
                        },
                        pagination: {
                            el: '#recent_see .swiper-pagination',
                            type: 'progressbar',
                        },
                    });
                }

                swiperJqzoomAndVisualScroller();

                setTimeout(function () {
                    $('#itm-' + new_itm_id).children('.imgProd').css({'opacity': '1'});
                }, 1000);
            }

            $(document).ready(function () {

                setTimeout(function () {

                    $(window).on('load resize', function () {

                        var img_max_height = $('body.homepage .main_wrapper#site_global_wrap .home-module.simple-gondole .home-column .productVisualMulti').width();
                        $('body.homepage .main_wrapper#site_global_wrap .home-module.simple-gondole .home-column .productVisualMulti').css('max-height', img_max_height);

                        var video_url_link = $('.visited_pdt_holder .productVisualMulti .productBigPictures iframe').width();
                        $('.visited_pdt_holder .productVisualMulti .productBigPictures .video_url').css('min-height', video_url_link);

                        var video_recemment = $('.visited_pdt_holder .swiper-slide .block_lnk iframe').width();

                        $('.mainSlideFp iframe.otherViewImg').height($('.mainSlideFp iframe.otherViewImg').width());
                        $('.productVisualMulti.productSwiper iframe').height($('.productVisualMulti.productSwiper iframe').width());

                        $('.visited_pdt_holder .swiper-slide .block_lnk iframe').css($('max-height', video_recemment));

                    }).trigger('load resize');
                }, 1000);

            });

            // we close the popin for "Diffuseur" products
            if (is_diffuseur == '1') {
                var id_popin;
                if (triggerType == 'sizeChange') {
                    id_popin = 'size_list_wrapper';
                }

                if (triggerType == 'colorChange') {
                    id_popin = 'color_list_wrapper';
                }
                closeMultiShad(id_popin);
            }
        }
    });
}

function loadMarque(actif_principal, actif_secondaires) {
    $.ajax({
        type: 'post',
        url: path_relative_root + create_link('ajax_get_marquebyid'),
        data: {'actif_principal': actif_principal, 'actifs_secondaires': actif_secondaires},
        dataType: 'json',
        success: function (response) {

            if (response.success) {

                for (var i = 0; i < response.result.length; i++) {

                    if (i == 0) {
                        $('#actif_principal #actif_secondaires_val_h4').html(response.result[i].marque_desc);
                        $('#actif_principal #actif_principal_val_h3').html(response.result[i].marque_titre);

                        var nom_marque = response.result[i].marque_nom.toLowerCase();
                        $('#actif_principal .links a').first().attr('href', path_relative_root + '#search=' + nom_marque + '&b=' + response.result[i].marque_id);
                        $('#actif_principal .links a').last().attr('href', path_relative_root + 'nos-plantes-et-actifs/' + response.result[i].marque_path + '.html');
                        $('#bloc_actif_principal img').attr('src', path_relative_root + 'img/marque/' + response.result[i].marque_id + '.jpg');
                        $('#actif_principal').attr('href', path_relative_root + 'nos-plantes-et-actifs/' + response.result[i].marque_path + '.html');
                    } else {
                        $('#actif_secondaire_' + i + ' .actif_secondaire_h3').html(response.result[i].marque_titre);
                        $('#actif_secondaire_' + i + ' .actif_secondaire_txt').html(response.result[i].marque_desc);
                        $('#actif_secondaire_' + i + ' img').attr('src', path_relative_root + 'img/marque/' + response.result[i].marque_id + '.jpg');
                        $('#actif_secondaire_' + i + ' a').attr('href', path_relative_root + 'nos-plantes-et-actifs/' + response.result[i].marque_path + '.html');
                    }
                }

                if (response.nb_actifs == 1) {
                    $('#bloc_actif_secondaire').hide();
                } else if (response.nb_actifs == 2) {
                    $('#actif_secondaire_2').hide();
                }

            } else {
                $('.actifs').hide();
            }
        }
    });
}

function loadcolor() {
    $("body.product_page").on("click", "#more_coloris", function () {
        $("body.product_page .choices_list").addClass("show_all");
        $("body.product_page #more_coloris").hide();
    });
}

/**
 * @param product_id : l'id du produit
 * @param regroup_ref_id : l'id du regroupement (si exist)
 * use in : app/views/_components/product/achat_express.php
 */
function achatExpressV2(product_id, regroup_ref_id) {
    var btn = $(this);
    // Loader
    btn.parent('.form_submit').addClass('loading');
    var elem = regroup_ref_id ? '_' + product_id + regroup_ref_id : '_' + product_id;
    var form = document.getElementById('prod_info' + elem);
    var color_id = form.elements['couleurProd'].value;
    var size_id = form.elements['tailleProd'].value;

    // Vérification si la taille sélectionnée n'est pas désactivée ce qui permet d'afficher l'alerte stock
    var tailleProdCrossSell = $('#qteProd_' + product_id + regroup_ref_id);

    var sizeQnt = form.elements['cnt_sizes'] ? form.elements['cnt_sizes'].value : $('#cnt_sizes' + product_id + '_' + regroup_ref_id);
    var colorQnt = form.elements['cnt_colors'] ? form.elements['cnt_colors'].value : $('#cnt_colors_' + product_id + '_' + regroup_ref_id);

    /*** Cacher les lots d'un produits qui est affiché ***/
    $(".batch_button").removeClass("actif");
    $(".batch_button").show();
    $(".formBatch").removeClass("open");
    $(".formBatch").hide();

    if (colorQnt > 1 && regroup_ref_id === '') {
        $(".form_itm.color input[name='itm_color']").each(function (index) {
            $(this).prop("checked", false);
        });
        $("#ligne_couleur_" + product_id + regroup_ref_id).fadeIn(600);
    } else if (colorQnt < 2 && sizeQnt > 1) {
        // Here we only have one color and several sizes, be sure to check availability when size selected
        $("#ligne_pointure_" + product_id + regroup_ref_id).fadeIn(600);
    } else {
        if (($('#size_' + product_id + "_" + size_id + regroup_ref_id, $('#prod_info' + elem)) != undefined && $('#size_' + product_id + "_" + size_id + regroup_ref_id, $('#prod_info' + elem)).length == 1 && $('#size_' + product_id + "_" + size_id + regroup_ref_id, $('#prod_info' + elem)).hasClass('disabled')) || ($('#tailleProd_' + product_id + '_' + regroup_ref_id).value == '0') || (typeof tailleProdCrossSell[0] != "undefined" && tailleProdCrossSell[0].value == "0")) {
            $(this).parent().hide();
            $('#bloc_add_alert' + elem).show();
        } else { // On ajoute bien le produit en faisant les vérifications sur la taille et la couleur
            // On regarde si la personne a sélectionenr la taille et la couleur
            if (color_id !== '' && size_id !== '') {
                if ($('.wrapperCartAssociations').length) {

                    waitingAddToBasket(event, form, 'basket', regroup_ref_id);
                } else {

                    waitingAddToBasket(event, form, '', regroup_ref_id);
                }

                setTimeout(function () {
                    btn.parent('.form_submit').removeClass('loading');
                }, 2000);

            } else if (color_id === '' && size_id === '') {
                alert(translate('choose_a_size_and_a_color'));
                // Loader
                btn.parent('.form_submit').removeClass('loading');
            } else if (color_id === '') {
                alert(translate('choose_a_color'));
                // Loader
                btn.parent('.form_submit').removeClass('loading');
            } else if (size_id === '') {
                alert(translate('choose_a_size'));
                // Loader
                btn.parent('.form_submit').removeClass('loading');
            }
        }
    }
}

// achat express init on swipers
function initAchatExpressSwipers() {

    var arrayElement = ['productColorFieldset', 'productSizeFieldset'];

    for (var elementSwiper of arrayElement) {
        if ($("#list_item .ligne_form." + elementSwiper + " .choices_list .form_itm").length >= 1) {
            $('#list_item .ligne_form.' + elementSwiper).each(function (e) {
                var id = $(this).attr('id');
                new Swiper('#' + id + ' .swiper-container', {
                    slidesPerView: 3.75,
                    spaceBetween: 16,
                    slideToClickedSlide: true,
                    navigation: {
                        nextEl: '#' + id + ' .swiper-button-next',
                        prevEl: '#' + id + ' .swiper-button-prev',
                    },
                    pagination: {
                        el: '#' + id + ' .swiper-pagination',
                        type: 'progress',
                    },
                });
            })
        }

    }
}

/**
 * @description Called upon color change in product page
 */
function onItemColorChange(form, is_regroup, itm_rr_id) {

    var control, prod_id, from_page;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);
    if ($('#couleurProd').val() !== undefined) {
        control = form.elements.namedItem(form.getValue('idCouleur'));
        control.value = $('#couleurProd').val().trim();

        // If it is achat_express_v2
        if (is_achat_express_v2 && ($('[name="itm_id_' + control.value + itm_rr_id + '"]').length > 1)) {
            // Find the input_check checked and look at your parent to find the right name=itm_id_.. inside as there are more than one
            var checkedColor_input = $(form).find('.input_check:checked');
            var checkedColor_value = checkedColor_input.parent().find('[name="itm_id_' + control.value + itm_rr_id + '"]').val();
            prod_id = checkedColor_value;
        } else {
            prod_id = form.getValue('itm_id_' + control.value + itm_rr_id).trim();
        }

        var is_assoc_product = form.getValue('isAssocProduct') ? form.getValue('isAssocProduct') : 0;

        if (is_assoc_product == true) {
            from_page = 'assoc';
        } else {
            from_page = 'product';
        }

        if (is_regroup) {
            loadProduct.call(form, prod_id, 'couleur', from_page, itm_rr_id, 'colorChange');
        } else {
            // Check if the product is already in the wishlist
            if (!is_achat_express_v2) {
                checkProductInWishlist(prod_id, itm_rr_id);
            } else {
                loadProduct.call(form, prod_id, 'couleur', from_page, itm_rr_id, 'colorChange', false);
            }

            var img = $(this).data('pic');
            changeVisu('img_large', img);

            // Define sizes that still have stock
            setAvailableSizes.call(this);
        }
    }
}

// Refreshes available quantity
function getAvailableQuantity(id, qty, from, idCouleur, itm_rr_id, is_wishlist) {
    itm_rr_id = (itm_rr_id !== undefined) ? itm_rr_id : '';
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + id + itm_rr_id);
    var list = $('.qte_select_wrapper', form).get(0);
    var plnb = form.getValue('produit_lot_nb');
    var pcol = form.getValue('idCouleur_' + id + '_' + itm_rr_id);
    var qty = (qty !== undefined) ? qty : 1;
    var is_achat_express = $('#is_achat_express_v2').val() == 1 && !is_wishlist;

    id = ((id !== undefined) ? id : '');

    if (typeof (pcol) == 'undefined') {
        var pcol = form.getValue('idCouleur');
    }

    var val_color = form.getValue(pcol);
    if (val_color == '0') {
        val_color = 'none';
    }

    var psze = form.getValue('idTaille');
    var val_size = form.getValue(psze);
    if (val_size == '0' && !(plnb > 0)) {
        val_size = 'none';
    }

    if (idCouleur !== undefined) {
        val_color = idCouleur;
        $('#' + pcol).val(idCouleur);
        $("#color_" + idCouleur).attr("checked", "checked");
    }

    var data = {
        idprod: form.getValue('produit_id'),
        idcouleur: val_color,
        idtaille: val_size,
        json: '1'
    };

    if (from == 'basket') {
        data.suff = form.getValue('produit_id');
    }

    if (plnb) {
        data.produit_lot_nb = plnb;
    }

    var product_has_stock;

    $.ajax({
        url: path_relative_root + create_link('ajax_liste_quantity'),
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        data: data,
        dataType: 'json',
        success: function (res) {
            var new_select;
            if (list) {
                list.innerHTML = res.content.trim();

                if (qty) {
                    new_select = form.elements.namedItem('qteProd');
                    if ($('[value="' + qty + '"]', new_select).length > 0) {
                        new_select.value = qty;
                    } else {
                        new_select.value = $('option:last', new_select).val();
                    }
                }
            }

            if ($("#date_du_ajax").val() == 0 && $("#date_au_ajax").val()) {
                $("#delivery_delay").hide();
            } else {
                $("#delivery_delay").show();
                $("#date_du").html($("#date_du_ajax").val());
                $("#date_au").html($("#date_au_ajax").val());
            }

            if ($('.txt_reason_borne').length > 0) {
                $('.info_magasin').html($('.txt_reason_borne').html());
                $('.info_magasin').show();
            }

            $('#liste_qte').html(res.content);
            $(".eclatProd").show();
            $(".precoDeliveryDate").show();

            if (!is_achat_express) {
                setTimeout(function () {
                    var qte_stock = $('#product_stock_counter').val();

                    if (qte_stock > 0) {
                        $(".eclat_product_epuise_fiche_produit").hide();
                        $(".eclat_product_nouveaute_fiche_produit").hide();
                        $(".eclatProd").show().removeClass('cache');
                        $("#bloc_add_basket").show();
                        $("#btn_add_cart").attr('disabled', false);
                        $("#bloc_add_alert").hide();
                        $(".stock_info.yes").show();
                        $(".stock_info.no").hide();
                        $(".productQuantityFieldset").removeClass("without_stock");
                        $(".qte_select_wrapper").removeClass("without_stock");
                        $(".chez_vous_entre").show();
                    } else {
                        $(".eclat_product_epuise_fiche_produit").removeClass('cache').show();
                        $(".eclat_product_nouveaute_fiche_produit").hide();
                        $(".eclatProd").hide();
                        $("#btn_add_alert").attr('disabled', false);
                        $("#bloc_add_basket").hide();
                        $(".wrapper_add_btn").hide();
                        $("#bloc_add_alert").show();
                        $(".stock_info.yes").hide();
                        $(".stock_info.no").show();
                        $(".productQuantityFieldset").addClass("without_stock");
                        $(".qte_select_wrapper").addClass("without_stock");
                        $(".chez_vous_entre").hide();
                    }
                }, 250);
            } else {
                if (res.stock_tampon == 0) {
                    if (wShop.$refs.wAlertestockForm) {
                        wShop.$refs.wAlertestockForm.reloadBloc += 1;
                    }

                    // DATALAYER V2
                    pushIntoDatalayer(res.dataLayer_object);
                    product_has_stock = false;
                } else {
                    // DATALAYER V2
                    pushIntoDatalayer(res.dataLayer_object);
                    product_has_stock = true;
                }
            }
        }
    });

    if (is_achat_express) {
        return product_has_stock;
    }
}

function closeFiltre() {
    $('#rayon_filters .filter_name.actif').removeClass('actif');
    $('#rayon_filters .selected_options.actif').removeClass('actif');
    $('#filter_sticky').removeClass('triggered');
    $('#trigger_filtre').removeClass('show');
    $('#site_head_wrap').removeClass('filters_in');
    $('#shad_link').removeClass('actif filterOn');
    $(".filtre_elem").each(function (index) {
        $(this).removeClass("is_checked");
    });
}
